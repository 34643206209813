import React from 'react'
import Icon from './icons'
import Container from './container'

import styles from './hero.module.css'

function Hero ({ children, palette, hideNext }) {
  return (
    <div className={`${styles.root} ${styles[palette]}`}>
        <Container>
            <h1 className={`${styles.headline} ${styles.bullet}`}>
                {children}
            </h1>
        </Container>
    </div>
  )
}

export default Hero
